import React from 'react'
import MuiTab from '@material-ui/core/Tab'
import styled from 'styled-components'
import { spacing } from '@material-ui/system';

const Tab = styled(({ color, padding, ...props }) => (
  <MuiTab {...props} />
))`
  ${spacing}
`

export default Tab
