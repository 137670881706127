import styled from 'styled-components';
import React from 'react';

import MuiDrawer from '@material-ui/core/Drawer';
import { sizing, palette } from '@material-ui/system';

const Drawer = styled(({ className, width, maxWidth, bgcolor, color, ...props }) => (
  <MuiDrawer {...props} classes={{ paper: className }} />
))`
    ${sizing}
    ${palette}
  `;

export default Drawer;
