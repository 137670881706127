import React from 'react'

import UiContainer from '@lib/ui/core/Container'

// eslint-disable-next-line prefer-arrow-callback
const Container = React.forwardRef(function Container(props, ref) {
  return <UiContainer maxWidth="lg" ref={ref} {...props} />
})

export default Container;
