import React from 'react'
import styled from 'styled-components'
import MuiToolbar from '@material-ui/core/Toolbar';
import { spacing } from '@material-ui/system';

const Toolbar = styled(({ px, py, ...props }) => (
  <MuiToolbar {...props} />
))`
  ${spacing}
`

export default Toolbar
