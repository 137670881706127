/* eslint-disable no-nested-ternary */
// import React, { useState } from 'react'
import React, { useState, useEffect } from 'react'
// import { Link as GatsbyLink } from 'gatsby'
import { navigate } from '@reach/router'
import { useStoreState, useStoreActions } from 'easy-peasy'

import Box from '@lib/ui/core/Box'
import AppBar from '@lib/ui/core/AppBar'
import Toolbar from '@lib/ui/core/Toolbar'
import Typography from '@lib/ui/core/Typography'
import IconButton from '@lib/ui/core/IconButton'
import ButtonBase from '@lib/ui/core/ButtonBase'
import SvgIcon from '@lib/ui/core/SvgIcon'
import TypographyLink from '@components/Link'
import Container from '@layouts/Container'
import styled from "styled-components"
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg"
import { test } from 'ramda'
import { ReactComponent as MenuIcon } from '@assets/icons/menu.svg'
import { ReactComponent as AccountIcon } from '@assets/icons/account.svg'
import { ReactComponent as ArrowIcon } from '@assets/icons/back-arrow.svg'
import { ReactComponent as Logo } from '@assets/logo.svg'

// import { redirectToSSOURL } from '@utils/url'
import { getToken, getRefreshToken, removeToken } from '@utils/auth'
import { logout } from '@services/api/auth'
import urljoin from 'url-join'

// import Menu from "../Menu"
import UserProfileImg from '@assets/images/profile.png'

const StlyedIcon = styled(SvgIcon)`
  path{
    fill: black;
  }
`


const NavItem = styled(Box)`
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
  display: inline-block;
  white-space: nowrap;
  margin: 0 20px;
  transition: all 200ms ease-in;
  position: relative;
  cursor: pointer;

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`
const pages = ['信用卡優惠', '信用卡比較', '回贈計數機'];
const Navigator = ({ children }) => (
  <Box position="absolute" left={8} zIndex={1}>
    <IconButton
      onClick={children}
    >
      <SvgIcon component={ArrowIcon} fontSize="1rem" color="text.primary" />
    </IconButton>
  </Box>
)
const Header = ({ headerTitle, closeDrawer, customHeader, customRight, elevation = 12, hideAvatar, type }) => {
  const isLogin = !!getToken()

  const loginUrl = typeof window !== `undefined` ? urljoin(process.env.GATSBY_NMG_AUTH_URL, `sso?app_id=${process.env.GATSBY_NMG_AUTH_APP_ID}&redirect_url=${window.location.href}`) : null

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  
  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  const showSearchDrawer = useStoreState(
    ({ offerSearch }) => offerSearch.showSearchDrawer
  )

  // const [state, setState] = useState(false);
  const [isShowProfilePopup, setIsShowProfilePopup] = useState(false)
  const profile = useStoreState((state) => state.user.profile)
  const loadProfile = useStoreActions((action) => action.user.loadProfile)
  const cleanUserData = useStoreActions((action) => action.user.cleanUserData)

  // const toggleDrawer = (open) => () => {
  //   setState(open);
  // };

  useEffect(() => {
    if (isLogin) loadProfile()
  }, [])

  const handleAccount = () => {
    if (isLogin) {
      setIsShowProfilePopup(!isShowProfilePopup)
    } else {
      navigate(loginUrl)
    }
  }

  const hideProfilePopup = () => {
    setIsShowProfilePopup(false)
  }

  const goToProfile = () => {
    navigate('/account')
  }

  const handleLogout = () => {
    logout({
      refresh_token: getRefreshToken(),
    }).then((res) => {
      if (res.status) {
        hideProfilePopup()
        removeToken()
        cleanUserData()
      }
    })
  }
  const updateSubcatChosen = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatChosen);
  const updateSubcatPayload = useStoreActions(({ offerSearch }) => offerSearch.updateSubcatPayload);
  const updateNeighborhoodPayload = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodPayload);
  const updatePageNumber = useStoreActions(({ offerSearch }) => offerSearch.updatePageNumber);
  const updateNeighborhoodChosen = useStoreActions(({ offerSearch }) => offerSearch.updateNeighborhoodChosen);
  const updateKeyword = useStoreActions(({ offerSearch }) => offerSearch.updateKeyword);
  const updateCardChosen = useStoreActions(({ offerSearch }) => offerSearch.updateCardChosen);
  const updateCardPayload = useStoreActions(({ offerSearch }) => offerSearch.updateCardPayload);
  const toggleFiltersDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleFiltersDrawer);
  const toggleBankDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleBankDrawer);
  const toggleSubcatDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleSubcatDrawer);
  const toggleRegionDrawer = useStoreActions(({ offerSearch }) => offerSearch.toggleRegionDrawer);

  const resetFilter = (url) => {
    ([])
    updateSubcatChosen([])
    updateSubcatPayload([])
    updateNeighborhoodChosen([])
    updateNeighborhoodPayload([])
    updateCardChosen([])
    updateKeyword(undefined)
    updatePageNumber(undefined)
    updateCardPayload([])
    updateCardChosen([])
    toggleFiltersDrawer(false)
    toggleBankDrawer(false)
    toggleSearchDrawer(false)
    toggleSubcatDrawer(false)
    toggleRegionDrawer(false)
    navigate(url)
  }

  const StyledCustomHeader = () => (
    <Container px={0}>
      <Box display="flex" alignItems="center" justifyContent="center" mx={{ xs: 1, sm: 1 }} mt={10} mb={0} height={{ xs: "2.5rem", sm: "3rem" }}>
        {customHeader}
      </Box>
    </Container>
  )
  return (
    <Box className="header">
      <AppBar bgcolor="white" position="fixed" elevation={elevation}>
      <Container maxWidth="lg">
        <Toolbar px={{ xs: 0, sm: 2 }}>
          <Box flexGrow={1} display="flex" justifyContent="left" alignItems="left">
            <Box display={{ xs: 'none', md: 'flex' }} flexDirection="row" clone>
              <Box onClick={() => resetFilter('/')}  cursor="pointer" data-ele-name="header-logo" fontSize="1.5rem" height="1em" width="auto" clone>
                <Logo />
              </Box>
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }}>
              <IconButton pl={0}
                onClick={handleOpenNavMenu}
              >
                <SvgIcon component={MenuIcon} color="text.primary" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem key="credit-card">
                  <TypographyLink onClick={() => resetFilter('/信用卡比較')} component="button" color="rgb(84,84,84)">
                    <Typography>信用卡比較</Typography>
                  </TypographyLink>
                </MenuItem>
                <MenuItem key="calculator">
                  <TypographyLink onClick={() => resetFilter('/回贈計數機')} component="button" color="rgb(84,84,84)">
                    <Typography>回贈計數機</Typography>
                  </TypographyLink>
                </MenuItem>
                <MenuItem key="posts">
                  <TypographyLink onClick={() => resetFilter('/posts')} component="button" color="rgb(84,84,84)">
                    <Typography>最新文章</Typography>
                  </TypographyLink>
                </MenuItem>
                <MenuItem key="offers">
                  <TypographyLink onClick={() => resetFilter('/offers')} component="button" color="rgb(84,84,84)">
                    <Typography>最新優惠</Typography>
                  </TypographyLink>
                </MenuItem>
                {/*<MenuItem key="註冊/登入" onClick={handleAccount}>
                  <Typography>註冊/登入</Typography>
                </MenuItem>*/}
              </Menu>
            </Box>
            <Box display={{ xs: 'flex', md: 'none' }} flexDirection="row" clone>
              <Box onClick={() => resetFilter('/')} cursor="pointer" data-ele-name="header-logo" alignSelf="center" fontSize="1.5rem" height="1em" width="auto" clone>
                <Logo />
              </Box>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} position="absolute" right={{ xs: "0rem", sm: "3.5rem" }} top={{ xs: "1rem", sm: "1rem" }}>
              {<NavItem onClick={() => resetFilter('/信用卡比較')} color={type === 'credit_card' ? "#CEA542" : "rgb(84,84,84)"}>
                信用卡比較
              </NavItem>}
              <NavItem onClick={() => resetFilter('/回贈計數機')} color={typeof window !== `undefined` && window.location.pathname === '/%E5%9B%9E%E8%B4%88%E8%A8%88%E6%95%B8%E6%A9%9F' ? "#CEA542" : "rgb(84,84,84)"}>
                回贈計數機
              </NavItem>
              <NavItem onClick={() => resetFilter('/posts')} color={type === 'post' ? "#CEA542" : "rgb(84,84,84)"}>
                最新文章
              </NavItem>
              <NavItem onClick={() => resetFilter('/offers')} color={type === 'offer' ? "#CEA542" : "rgb(84,84,84)"}>
                最新優惠
              </NavItem>
              {/*<Typography component="span" style={{ cursor: 'pointer', fontWeight:'700', 'background':'#FBE74D', padding:'5px 20px', 'margin': '-5px 20px 0 20px', borderRadius: '2rem'}} onClick={handleAccount}>註冊/登入</Typography>*/}
            </Box>
            {/*  
            <Box position="absolute" right={{ xs: "0rem", sm: "2rem" }} bottom={{ xs: "0rem", sm: "0.5rem" }}>
              {(!closeDrawer && !hideAvatar) && (
                <IconButton aria-label="signin" onClick={handleAccount} p={1}>
                  {isLogin
                    ? <img style={{ width: "2rem", height: "2rem", borderRadius: "50%" }} src={profile.avatar || UserProfileImg} alt="avatar" />
                    : <SvgIcon fontSize="inherit" component={AccountIcon} />}
                </IconButton>
              )}
              {isShowProfilePopup
                ? (
                  <Box
                    minWidth={{ xs: "5rem", sm: "5.5rem" }}
                    bgcolor="background.default"
                    position="absolute"
                    top="2.4rem"
                    right=".6rem"
                    boxShadow={2}
                    borderRadius=".5rem"
                    textAlign="right"
                    tabIndex={0}
                    py=".2rem"
                    onMouseLeave={hideProfilePopup}
                    onBlur={hideProfilePopup}
                  >
                    <Box>
                      <Box px={1.5} pt={1} display="inline-block" textAlign="right" clone>
                        <ButtonBase onClick={goToProfile} style={{ width: '100%' }}><Typography>個人主頁</Typography></ButtonBase>
                      </Box>
                    </Box>
                    <Box>
                      <Box px={1.5} py={1} display="inline-block" textAlign="right" clone>
                        <ButtonBase onClick={handleLogout} style={{ width: '100%' }}> <Typography>登出</Typography></ButtonBase>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
              */}
            <Box position="absolute" right={{ xs: "0rem", sm: "1rem" }} top={{ xs: "1rem", sm: "1rem" }} color="text.primary">
              <IconButton onClick={() => toggleSearchDrawer(true)} data-ele-name="header-search-icon" p={0}>
              <StlyedIcon
                fontSize={{ xs: "1rem", sm: "1.5rem" }}
                component={SearchIcon}
                color="inherit" 
              />
              </IconButton>
            </Box>
            {customRight && <Box position="absolute" right={{ xs: "2.5rem", sm: "3.5rem" }}>{customRight}</Box>}
          </Box>
        </Toolbar>
        {/* { headerTitle && (
            <Box width="2.5rem" height="2.5rem" display="inline-block" />
          )} */}
        </Container>
      </AppBar>
      <Toolbar>
        {customHeader && (<StyledCustomHeader />)}
      </Toolbar>
    </Box>
  )
}


Header.defaultProps = {
  siteTitle: ``,
}

export default Header
