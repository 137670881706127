import styled from 'styled-components';
import MuiDivider from '@material-ui/core/Divider';
import { spacing, palette, sizing } from '@material-ui/system';

const Divider = styled(MuiDivider)`
    ${spacing}
    ${palette}
    ${sizing}
`;

export default Divider;
