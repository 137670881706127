// import React from 'react'
import styled from 'styled-components';
import MuiInputBase from '@material-ui/core/InputBase';
import { typography, sizing } from '@material-ui/system';

// const InputBase = styled(({ InputLabelProps, InputProps, ...props }) => (
//   <MuiInputBase {...props} />
// ))`
//   ${typography}
// `
const InputBase = styled(MuiInputBase)`
    ${typography}
    ${sizing}
`;
export default InputBase
