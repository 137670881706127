import React from 'react';
import styled from 'styled-components';
import MuiDialog from '@material-ui/core/Dialog';
import { sizing, spacing, borders } from '@material-ui/system';

const Dialog = styled(({ className, width, height, maxWidth, minWidth, maxHeight, minHeight, px, py, pt, pb, borderRadius, ...props }) => (
  <MuiDialog {...props} classes={{ paper: className }} />
))`
  ${sizing}
  ${spacing}
  ${borders}
`;

Dialog.defaultProps = {
  borderRadius: "1rem",
}
export default Dialog
