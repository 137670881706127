import React from 'react'
import styled from 'styled-components'

import Container from '@layouts/Container'
import Box from '@lib/ui/core/Box'
import Typography from '@lib/ui/core/Typography'
import Link from '@lib/ui/core/Link'
// import SvgIcon from '@lib/ui/core/SvgIcon'

// import { ReactComponent as FBIcon } from '@assets/icons/fb.svg'
// import { ReactComponent as IGIcon } from '@assets/icons/ig.svg'

const StyledList = styled.ol`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Footer = () => {
  const today = new Date();
  return (
    <Box component="footer" bgcolor="background.footer" color="info.contrastText" py={2.5}>
      <Container>
        <Box display="flex" mb={2.5}>
          <Box flexGrow={1}>
            <Box display="flex" component={StyledList}>
              <Box mr={2} component="li">
                <Link href="https://www.nmg.com.hk/privacy/" target="_blank" color="inherit">
                  <Typography variant="body2" component="span">私隱政策</Typography>
                </Link>
              </Box>
              <Box mr={2} component="li">
                <Link href="https://www.nmg.com.hk/disclaimer/" target="_blank" color="inherit">
                  <Typography variant="body2" component="span">免責聲明</Typography>
                </Link>
              </Box>
              <Box component="li">
                <Link href="/contactus" target="_blank" color="inherit">
                  <Typography variant="body2" component="span">聯絡我們</Typography>
                </Link>
              </Box>
            </Box>
          </Box>
          {/* <Box>
            <Box mr={1} clone>
              <SvgIcon><FBIcon /></SvgIcon>
            </Box>
            <SvgIcon><IGIcon /></SvgIcon>
          </Box> */}
        </Box>
        <Box>
          <Typography variant="body2" color="inherit">©{today.getFullYear()} 版權所有 | 保留所有權利</Typography>
        </Box>
      </Container>
    </Box>
  )
}


export default Footer
