import React from 'react';
import styled from 'styled-components'
import MuiSvgIcon from '@material-ui/core/SvgIcon';
import { palette, typography, spacing } from '@material-ui/system';

const SvgIcon = styled(({ color, fontSize, ...props }) => (
  <MuiSvgIcon {...props} />
))`
  ${palette}
  ${typography}
  ${spacing}
`

SvgIcon.defaultProps = {
  color: 'text.primary',
  fontSize: '1rem',
}

export default SvgIcon;
