import React from 'react'
import styled from 'styled-components';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import { typography, borders } from '@material-ui/system';

const Autocomplete = styled(({ className, borderRadius, ...props }) => (
  <MuiAutocomplete {...props} classes={{ paper: className }} />
))`
    ${typography}
    ${borders}
  `;

Autocomplete.defaultProps = {
  borderRadius: "1rem",
}

export default Autocomplete;
