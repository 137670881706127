import React from 'react'
import MuiTabs from '@material-ui/core/Tabs'
import { spacing } from '@material-ui/system';
import styled from 'styled-components'

const Tabs = styled(({ ...props }) => (
  <MuiTabs {...props} />
))`
  ${spacing}
`;

export default Tabs
