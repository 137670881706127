import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'react-slick-styles/slick/slick.css';
import 'react-slick-styles/slick/slick-theme.css';

const size = '8px';

const DotContainer = styled.div`
  bottom: 0;
  text-align: center;

  &.slick-dots li {
    width: ${size};
    height: ${size};
    margin: 0 ${size};
  }

  &.slick-dots li:last-child {
    margin-right: 0;
  }

  &.slick-dots li button {
    width: ${size};
    height: ${size};
  }

  &.slick-dots li button:before {
    content: '';
    opacity: 1;
    background-color: ${({ theme }) => `${theme.palette.common.white}`};
    width: ${size};
    height: ${size};
    transition: ${({ theme }) => (theme.transitions.create(['background-color', 'opacity']))};
  }

  // &.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  //   opacity: .75;
  //   background-color: ${({ theme }) => `${theme.palette.primary.main}`};
  // }
  
  &.slick-dots li.slick-active button:before {
    opacity: 1;
    background-color: ${({ theme }) => `${theme.palette.primary.main}`};
  }
`;

const DotList = styled.ul`
  display: inline-block;
  margin: 0;
  padding-left: 0;
  padding-right: 1rem;
  padding-bottom: 1rem;
`;

const StyledSlider = styled(Slider)`
  .slick-slide > div {
    font-size: 0;
  }
  .slick-slide > div > * {
    font-size: 1rem;
  }
  .slick-slide.slick-active {
    z-index: 1;
  }
  .slick-prev {
    left: 25px;
    z-index: 999;
    width: 40px;
    height: 40px;
  }
  .slick-next {
    right: 25px;
    z-index: 999;
    width: 40px;
    height: 40px;
  }
  .slick-prev:before, .slick-next:before {
    font-size: 40px;
  }
`

const Carousel = StyledSlider;

Carousel.defaultProps = {
  dots: true,
  infinite: true,
  speed: 300,
  autoplaySpeed: 5000,
  autoplay: false,
  pauseOnHover: false,
  arrows: true,
  appendDots: (dots) => (
    <DotContainer>
      <DotList>{dots}</DotList>
    </DotContainer>
  ),
}

export default Carousel;
