import React from 'react';
import styled from 'styled-components';

import MuiSwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { sizing, palette } from '@material-ui/system';

const SwipeableDrawer = styled(({ className, width, maxWidth, bgcolor, color, ...props }) => (
  <MuiSwipeableDrawer {...props} classes={{ paper: className }} />
))`
    ${sizing}
    ${palette}
  `;

export default SwipeableDrawer;
