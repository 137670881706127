import React from 'react'
import { ReactComponent as Logo } from '@assets/icons/symbol-logo.svg'
import { Box, SvgIcon, Typography, ButtonBase } from '@lib/ui/core'

const FloatingButton = () => (
  <ButtonBase href="https://jetsobee.app.link/1YoKBtiOl8" target="_blank" data-ele-name="floating-button">
    <Box boxShadow={9} bgcolor="background.default" borderRadius="1rem" p={1.5} display="flex" alignItems="center">
      <Box mr={1} display="flex" alignItems="center"><SvgIcon fontSize="2.5rem" component={Logo} /></Box>
      <Box display="block">
        <Typography variant="subtitle2">
          立即下載
        </Typography>
        <Typography color="secondary" variant="subtitle2">
          手機 App
        </Typography>
      </Box>
    </Box>
  </ButtonBase>
)

export default FloatingButton
