import React from 'react'
import styled from 'styled-components'
import MuiDialogContent from '@material-ui/core/DialogContent';
import { sizing, spacing } from '@material-ui/system';

const DialogContent = styled(({ width, height, maxWidth, minWidth, maxHeight, minHeight, px, py, pt, pb, ...props }) => (
  <MuiDialogContent {...props} />
))`
  ${spacing}
  ${sizing}
`

DialogContent.defaultProps = {
  px: { xs: 2, sm: 4 },
  pb: { xs: 2.5, sm: 4 },
  pt: { xs: 1.25, sm: 2 },
}
export default DialogContent
