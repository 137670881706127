import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '../DialogTitle';
import MuiDialogContent from '../DialogContent';
import Box from '../Box';
import Dialog from '../Dialog';
import IconButton from '../IconButton';
import SvgIcon from '../SvgIcon';
import Typography from '../Typography';

// eslint-disable-next-line prefer-arrow-callback
const DialogWithClose = React.forwardRef(function DialogWithClose(props, ref) {
  const { closeIcon: IconComponent, title, onClose, children, ...rest } = props;

  return (
    <Dialog onClose={onClose} ref={ref} {...rest}>
      <MuiDialogTitle disableTypography>
        { title && (
          <Typography variant="subtitle1">{title}</Typography>
        )}
        <Box position="absolute" right={4} top={4}>
          <IconButton aria-label="close" onClick={onClose}>
            {IconComponent ? (
              <SvgIcon component={IconComponent} />
            ) : (
              <SvgIcon component={CloseIcon} />
            )}
          </IconButton>
        </Box>
      </MuiDialogTitle>
      <MuiDialogContent>
        { children }
      </MuiDialogContent>
    </Dialog>
  );
});

DialogWithClose.defaultProps = {
  minWidth: { sm: 568 },
  width: { xs: "100%", sm: "auto" },
}

export default DialogWithClose;
