import React from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';

const AppBar = ({ bgcolor, color, ...props }) => (
  <Box bgcolor={bgcolor} color={color} clone>
    <MuiAppBar {...props} color="inherit" />
  </Box>
)

AppBar.defaultProps = {
  bgcolor: 'primary',
}

export default AppBar;
