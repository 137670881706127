/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import CssBaseline from '@material-ui/core/CssBaseline';
import { createGlobalStyle } from "styled-components";

import Header from '@components/Header'
import Box from '@lib/ui/core/Box'
import Footer from '@components/Footer'
import FloatingButton from '@components/FloatingButton'
import useDeviceDetect from "@hooks/use-device-detect";

export const GlobalStyle = createGlobalStyle`
  :focus {
    outline: none;
  }
`;

const Layout = ({ children, headerTitle, closeDrawer, customHeader, customRight, elevation, hideAvatar, type }) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { isMobile } = useDeviceDetect();

  return (
    <Box display="flex" minHeight="100vh" flexDirection="column">
      <CssBaseline />
      <GlobalStyle />
      <Header siteTitle={site.siteMetadata.title} headerTitle={headerTitle} closeDrawer={closeDrawer} customHeader={customHeader} customRight={customRight} elevation={elevation} hideAvatar={hideAvatar} type={type}/>
      <Box flexGrow={1}>
        <main>{children}</main>
      </Box>
      { isMobile
        && (
        <Box position="fixed" bottom={80} right={16}>
          <FloatingButton />
        </Box>
        ) }
      <Box>
        <Footer />
      </Box>
    </Box>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
