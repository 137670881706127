import React from 'react'
import styled from 'styled-components'
import MuiPaper from '@material-ui/core/Paper';
import { palette, borders, flexbox, sizing, display, spacing } from '@material-ui/system';

const Paper = styled(({ borderRadius, alignItems, flexGrow, ...props }) => (
  <MuiPaper {...props} />
))`
  ${palette}
  ${borders}
  ${flexbox}
  ${sizing}
  ${display}
  ${spacing}
`

export default Paper
