import React from 'react'
import styled from 'styled-components'
import MuiDialogActions from '@material-ui/core/DialogActions';
import { sizing, spacing } from '@material-ui/system';

const DialogActions = styled(({ width, height, maxWidth, minWidth, maxHeight, minHeight, px, py, pt, pb, ...props }) => (
  <MuiDialogActions {...props} />
))`
  ${spacing}
  ${sizing}
`

DialogActions.defaultProps = {
  px: { xs: 2, sm: 4 },
  py: { xs: 2.5, sm: 4 },
}
export default DialogActions
