import React from 'react'
import styled from 'styled-components'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { sizing, spacing } from '@material-ui/system';

const DialogTitle = styled(({ width, height, maxWidth, minWidth, maxHeight, minHeight, px, py, pt, pb, ...props }) => (
  <MuiDialogTitle {...props} />
))`
  ${spacing}
  ${sizing}
`

DialogTitle.defaultProps = {
  px: { xs: 2, sm: 4 },
  pt: { xs: 2.5, sm: 4 },
  pb: { xs: 1.25, sm: 2 },
}
export default DialogTitle
